body {
  background-color: #1a202c;
}

.App {
  text-align: center;
  color: white;
  padding: 40px 0;
  min-height: calc(100vh - 80px);
}

.Main {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.App-main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.App-square {
  margin-bottom: 20px;
}

.App-title {
  font-size: 1.8rem;
  color: #edf2f7;
  font-weight: normal;
  margin: 30px 0;
}

.App-title span {
  display: block;
  font-size: 2.25rem;
  font-weight: bold;
}

.App a {
  color: #edf2f7;
}

.Button {
  display: inline-block;
  background-color: #2f394e;
  border-radius: 4px;
  padding: 7px 12px;
}

.Button:hover {
  background-color: #414e6b;
}

a.Button, .Button a {
  text-decoration: none;
}

@media screen and (max-width: 620px) {
  .App-title {
    font-size: 1.5rem;
  }

  .App-title span {
    font-size: 1.7rem;
  }
}
